<template>
  <!-- 仓库管理 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="80px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="仓库名称">
              <el-input v-model="form.warehouseName" :placeholder="$t('page.inputPlaceholder')" /></el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="组织机构" prop="organization">
              <GroupTree :value="form.organization" filterable :placeholder="$t('page.selectPlaceholder')" @seletChange="seletChange" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.Linkman')"><el-input v-model="form.contactStaff" :placeholder="$t('page.inputPlaceholder')" /></el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域类型">
              <el-select v-model="form.warehouseLocation" :placeholder="$t('page.selectPlaceholder')">
                <el-option label="国内" value="1" />
                <el-option label="国外" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="仓库类型">
              <el-select v-model="form.warehouseType" :placeholder="$t('page.selectPlaceholder')">
                <el-option label="自管仓" :value="3" />
                <el-option label="第三方仓" :value="4" />
                <el-option label="平台仓" :value="5" />
                <el-option label="工厂仓" :value="6" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.status')">
              <el-select v-model="form.useStatus" :placeholder="$t('page.selectPlaceholder')">
                <el-option v-for="item in getDictionariesOpt('USE_STATUS')" :key="item.val" :value="item.val" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <router-link to="added" append><el-button class="mr-3" icon="el-icon-plus" type="primary">{{ $t('page.create') }}</el-button></router-link>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table ref="multipleTable" :header-cell-style="{background:'#fafafa'}" class="mb-3" :data="tableDatas">
      <el-table-column
        type="selection"
        width="55"
      />
      <el-table-column
        :label="$t('page.No')"
        type="index"
      >
        <template slot-scope="scope">
          {{ scope.row.No }}
        </template>
      </el-table-column>
      <el-table-column
        prop="warehouseName"
        label="仓库名称"
      />

      <el-table-column
        prop="warehouseShortName"
        label="仓库简称"
      />
      <el-table-column
        prop="organizationName"
        label="组织机构"
        width="140"
      />
      <el-table-column
        label="货主"
        prop="cargoOwnerName"
      />
      <el-table-column
        prop="tenants"
        label="所属租户"
      />
      <el-table-column
        prop="warehouseLocation"
        label="区域类型"
      >
        <template slot-scope="scope">{{ scope.row.warehouseLocationI18 }}</template>
      </el-table-column>
      <el-table-column
        prop="warehouseLocation"
        label="仓库类型"
      >
        <template slot-scope="scope">{{ scope.row.warehouseTypeI18 }}</template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        show-overflow-tooltip
      />
      <el-table-column
        prop="contactStaff"
        :label="$t('page.Linkman')"
      />
      <el-table-column
        prop="contactPhone"
        label="联系电话"
      />
      <el-table-column
        prop="remark"
        label="备注"
      />
      <el-table-column
        prop="useStatusI18"
        :label="$t('page.status')"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.useStatus == 1" class="use-status-open">{{ scope.row.useStatusI18 }}</span>
          <span v-else class="use-status-close">{{ scope.row.useStatusI18 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('page.createTime')"
      />
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="100"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyClick(scope.row.id,false)">{{ $t('page.modify') }}</el-button>
          <el-button type="text" size="small" @click="modifyClick(scope.row.id,true)">{{ $t('page.view') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        class="pt-4 text-xs-right"
        :current-page="pager.current"
        :page-sizes="[20,40,100,200,300,400,500]"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { queryWarehouse } from '@/api/listSelection'
// import ExcelExportFile from '@/components/ImportButton'
import { Mixin } from '@/mixin/mixin.js'
import GroupTree from '@/components/ListSelection/GroupTree'

export default {
  components: {
    GroupTree
  },
  mixins: [Mixin],
  data() {
    return {
      exportKey: 'wms_warehouse',
      showRow: false, // 切换显示input
      tableDatas: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      form: {
        warehouseName: '',
        organization: ''
      }
    }
  },
  computed: {
    warehouse() { // 获取仓库id
      // const warehouseId = this.$store.getters.warehouse
      return this.$store.getters.warehouse
    },
    // 提取参数
    parameter() {
      return Object.assign({}, this.pager, this.form)
    }
  },
  mounted() {
    this._queryWarehouse(this.parameter)
  },
  methods: {
    seletChange(val) {
      this.form.organization = val
      // const { length, last = length - 1 } = val
      // if (length) this.form.organization = val[last]
    },
    // 点击查看/修改跳转到仓库管理新增/修改页
    modifyClick(row, disabled) {
      this.$router.push({
        path: 'added',
        append: true,
        query: { 'id': row, 'disabled': disabled }
      })
    },
    // 点击查询获取信息
    queryClick(query) {
      const { organization = [] } = this.form
      // const parameter = Object.assign({}, this.form, this.warehouse)
      const parameter = Object.assign({}, this.form, { organization: organization[organization.length - 1] })
      this._queryWarehouse(parameter)
    },
    // 点击重置清空文本框信息
    resetClick(val) {
      this.form = {
        organization: ''
      }
      this.queryClick()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取仓库信息数据
    async _queryWarehouse(parameter) {
      // parameter.id = this.$store.getters.warehouse
      let No = 0
      const { datas: { pager, records }} = await queryWarehouse(parameter)
      No = pager.current * pager.size - pager.size
      this.tableDatas = records.map(e => { e.No = No += 1; return e })
      this.pager = pager
    },

    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._queryWarehouse(this.parameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._queryWarehouse(this.parameter)
    }

  }
}
</script>
